import React, { useState } from 'react';

const AddDictionary = () => {
  const [formData, setFormData] = useState({
    term: '',
    definition: '',
    tags: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiEndpoint = 'https://api-lqdwzbqqpa-uc.a.run.app/term';
    const token = localStorage.getItem('AuthToken');
  
    fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        term: formData.term,
        desc: formData.definition,
        tags: formData.tags,
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Success:', data);
      alert('Term successfully added.');
      setFormData({
        term: '',
        definition: '',
        tags: '',
      });
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Error adding a term.');
    });
  };
  

  return (
    <form onSubmit={handleSubmit} style={{display: 'grid', gridTemplateColumns: '1fr', textAlign: 'center' }}>
      <label style={{marginBottom: '10px'}}>
        Term:
        <br />
        <input style={{marginTop: '5px'}} type="text" name="term" value={formData.term} onChange={handleChange} required />
      </label>
      <label style={{marginBottom: '10px'}}>
        Definition:
        <br />
        <textarea style={{marginTop: '5px'}} name="definition" value={formData.definition} onChange={handleChange} required ></textarea>
      </label>
      <label style={{marginBottom: '10px'}}>
        Tags:
        <br />
        <input style={{marginTop: '5px'}} name="tags" value={formData.tags} onChange={handleChange} />
      </label>
      <button style={{ borderRadius: '10px', width: '200px', margin: '0 auto', marginTop: '10px', padding: '7px', cursor: 'pointer'}} type="submit">Submit</button>
    </form>
  );
};

export default AddDictionary;
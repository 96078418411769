// Importing required libraries, modules, and components
import React from "react"; 
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
// Components
import Navbar from "./components/Navbar";
import Footer from './components/Footer';
import BlogPost from './components/BlogPost';

// Pages
import Home from './pages/Home';
import About from './pages/About'; 
import Blog from './pages/Blog';
import Calculators from './pages/Calculators'
import Resources from './pages/Resources';
import Admin from './pages/Admin';
import Support from './pages/Support';
import Glossary from './pages/Glossary';
import NotFound from './pages/NotFound'; 

// Main App component
function App() {

  return (
    <div className="App">
      <Router>
        <Navbar />  {/* Navigation bar component */}
          <div className="routes">
            {/* Defining application routes */}
            <Routes>
              <Route index path="/" element={<Home />} />  {/* Home Page */}
              <Route path="/about" element={<About />} />  {/* About Page */}
              <Route path="/admin" element={<Admin />} />  {/* Admin Page */}
              <Route path="/blog" element={<Blog />} />  {/* Blog Page */}
              <Route path="/blog/:title" element={<BlogPost />} /> 
              <Route path="/calculators" element={<Calculators />} />  {/* Calculators Page */}
              <Route path="/glossary" element={<Glossary />} />  {/* Glossary Page */}
              <Route path="/resources" element={<Resources />} />  {/* Resources Page */}
              <Route path="/support" element={<Support />} />  {/* Support Page */}
              <Route path="*" element={<NotFound />} />  {/* 404 Page */}
            </Routes>
          </div>
          <Footer />  {/* Footer bar component */}
      </Router>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';

const EditDictionary = () => {
  const [terms, setTerms] = useState([]);
  const [glossary, setGlossary] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [formData, setFormData] = useState({
    term: '',
    desc: '',
    tags: '',
  });
  const [showDropdown, setShowDropdown] = useState(true); 

  const fetchGlossaryData = () => {
    fetch('https://api-lqdwzbqqpa-uc.a.run.app/glossary')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setGlossary(data);
        const sortedTerms = data.map(item => item.term).sort((a, b) => a.localeCompare(b));
        setTerms(sortedTerms);
      })
      .catch(error => {
        console.error('Error fetching terms:', error);
      });
  };

  useEffect(() => {
    fetchGlossaryData();
  }, []);
  
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (window.confirm('Do you want to proceed with the changes?')) {
      const apiEndpoint = `https://api-lqdwzbqqpa-uc.a.run.app/term/${selectedTerm}`;
      const token = localStorage.getItem('AuthToken');
  
      fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          term: formData.term,
          desc: formData.desc,
          tags: formData.tags,
        }),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update the term.');
        }
        return response.json();
      })
      .then(data => {
        console.log('Success:', data);
        alert('Term successfully edited.');
        fetchGlossaryData();
        setSearch('');
        setShowDropdown(true)
        setFormData({
          term: '',
          definition: '',
          tags: '',
        });
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Error editing a term.');
      });
    }
  };
  
  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this term?')) {
      const apiEndpoint = `https://api-lqdwzbqqpa-uc.a.run.app/term/${selectedTerm}`;
      const token = localStorage.getItem('AuthToken'); 
  
      fetch(apiEndpoint, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete the term.');
        }
        return response.json();
      })
      .then(data => {
        console.log('Success:', data);
        alert('Term successfully deleted.');
        fetchGlossaryData();
        setSearch('');
        setShowDropdown(true)
        setFormData({
          term: '',
          desc: '',
          tags: '',
        });
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Error deleting a term.');
      });
    }
  };  

  const filteredTerms = terms.filter((term) => term && term.toLowerCase().includes(search.toLowerCase()));

  return (
    <div>
      <input
        style={{
            width: '90%',
            backgroundColor: 'lightgray',
        }}
        type="text"
        placeholder="Search term"
        value={search}
        onChange={(e) => { setSearch(e.target.value); setShowDropdown(true); }}
      />
      {showDropdown && (
        <ul style={{ 
            textAlign: 'left', 
            listStyleType: 'none', 
            backgroundColor: 'lightgray',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.2)',
            padding: '5px',
            maxHeight: '30vh',
            width: '88%',
            margin: '0 auto',
            cursor: 'pointer'
        }}>
          {filteredTerms.map((term, index) => (
            <li key={index} onClick={() => {
              const selectedData = glossary.find(item => item.term === term);
              setSearch(selectedData.term);
              setSelectedTerm(selectedData.term);
              setFormData({
                term: selectedData.term,
                desc: selectedData.desc,
                tags: selectedData.tags,
              });
              setShowDropdown(false); 
            }}>
              {term}
            </li>
          ))}
        </ul>
      )}
      {selectedTerm && (
        <form onSubmit={handleSubmit} style={{display: 'grid', gridTemplateColumns: '1fr', textAlign: 'center', marginTop: '20px' }}>
          <label style={{marginBottom: '10px'}}>
            Term:
            <br />
            <input style={{marginTop: '5px'}} type="text" name="term" value={formData.term} onChange={handleChange} />
          </label>
          <label style={{marginBottom: '10px'}}>
            Definition:
            <br />
            <textarea style={{marginTop: '5px'}} name="desc" value={formData.desc} onChange={handleChange}></textarea>
          </label>
          <label style={{marginBottom: '10px'}}>
            Tags:
            <br />
            <input style={{marginTop: '5px'}} type="text" name="tags" value={formData.tags} onChange={handleChange} />
          </label>
          <button style={{ borderRadius: '10px', width: '200px', margin: '0 auto', marginTop: '10px', padding: '7px', cursor: 'pointer'}}  type="submit">Submit</button>
          <button style={{ borderRadius: '10px', width: '200px', margin: '0 auto', marginTop: '10px', padding: '7px', cursor: 'pointer', backgroundColor: 'red', color: 'white'}}  type="button" onClick={handleDelete}>Delete</button>
        </form>
      )}
    </div>
  );
};

export default EditDictionary;

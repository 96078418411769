import React, { useState, useEffect } from 'react';
import calculationsData from './data/sandbed.json';
import sandData from './data/sand.json';
import './styles.css';

const Sandbed = () => {
  const [selectedShape, setSelectedShape] = useState(calculationsData[0].shape);
  const [selectedSand, setSelectedSand] = useState(sandData[0].type);
  const [inputs, setInputs] = useState({});
  const [result, setResult] = useState(null);
  const [inputUnit, setInputUnit] = useState('meters');
  const [sandHeightUnit, setSandHeightUnit] = useState('centimeters');
  const [weightUnit, setWeightUnit] = useState('pounds');

  const handleSelectChange = (event) => {
    setSelectedShape(event.target.value);
    setInputs({});
    setResult(null);
  };

  const handleSandSelectChange = (event) => {
    setSelectedSand(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: parseFloat(value)
    }));
  };

  const calculateVolumeAndWeight = () => {
    const calculation = calculationsData.find((calc) => calc.shape === selectedShape);
    let sandHeight = inputs.sand_height || 0;
  
    if (sandHeightUnit === 'inches') {
      sandHeight /= 12;
    } else if (sandHeightUnit === 'centimeters') {
      sandHeight *= 0.0328084;
    }
  
    const formulaInputs = calculation.inputs.map(input => {
      let value = inputs[input] || 0;
      if (inputUnit === 'meters') {
        value *= 3.28084; 
      } else if (inputUnit === 'inches') {
        value /= 12;
      }
      return value;
    });

    let calculatedVolume;

    if (selectedShape === 'drop-off tank') {
      let { side1, side2, depth, height1 } = inputs;

      if (inputUnit === 'meters') {
        side1 *= 3.28084;
        side2 *= 3.28084;
        depth *= 3.28084;
        height1 *= 3.28084;
      } else if (inputUnit === 'inches') {
        side1 /= 12;
        side2 /= 12;
        depth /= 12;
        height1 /= 12;
      }
    
      if (sandHeight > height1) {
        calculatedVolume = (side2 * height1 * depth) + ((side1 + side2) * (sandHeight - height1) * depth);
      } else {
        const formula = new Function(...calculation.inputs, `return ${calculation.formula};`);
        let areaInSquareFeet = formula(...formulaInputs);
        calculatedVolume = areaInSquareFeet * sandHeight;
      }
    } else {
      const formula = new Function(...calculation.inputs, `return ${calculation.formula};`);
      let areaInSquareFeet = formula(...formulaInputs);
      calculatedVolume = areaInSquareFeet * sandHeight;
    }
  
    const selectedSandType = sandData.find(sand => sand.type === selectedSand);
    let weight = calculatedVolume * selectedSandType.amount; 
  
    if (weightUnit === 'kilograms') {
      weight *= 0.453592; 
    }
  
    setResult(weight);
  };
  
  const getImagePath = (shape) => {
    try {
      return require(`./images/sandbed/${shape}.png`);
    } catch {
      return null;
    }
  };

  useEffect(() => {
    if (result !== null) {
      calculateVolumeAndWeight();
    }
  }, [weightUnit, inputs]);

  return (
    <div>
      <select className='dropdown' value={selectedShape} onChange={handleSelectChange}>
        {calculationsData.map((calc) => (
          <option key={calc.shape} value={calc.shape}>
            {calc.shape.charAt(0).toUpperCase() + calc.shape.slice(1)}
          </option>
        ))}
      </select>
      <div className="calculator-image">
        {getImagePath(selectedShape) && (
          <img src={getImagePath(selectedShape)} alt={selectedShape} />
        )}
      </div>
      <div>
        <select className='dropdown' value={selectedSand} onChange={handleSandSelectChange}>
          {sandData.map((sand) => (
            <option key={sand.type} value={sand.type}>
              {sand.type}
            </option>
          ))}
        </select>
      </div>
      <div>
        <input
          className='input'
          type='number'
          name='sand_height'
          value={inputs.sand_height || ''}
          onChange={handleInputChange}
          placeholder='Sand Height'
          required
        />
        <div className='unit-selector'>
          <label><input type="radio" value="centimeters" name="sandHeightUnit" checked={sandHeightUnit === 'centimeters'} onChange={() => setSandHeightUnit('centimeters')} />Centimeters</label>
          <label><input type="radio" value="inches" name="sandHeightUnit" checked={sandHeightUnit === 'inches'} onChange={() => setSandHeightUnit('inches')} />Inches</label>
        </div>
      </div>
      <div>
        {calculationsData.find((calc) => calc.shape === selectedShape).inputs.map((input) => (
          <input
            className='input'
            key={input}
            type="number"
            name={input}
            value={inputs[input] || ''}
            onChange={handleInputChange}
            placeholder={input.charAt(0).toUpperCase() + input.slice(1)}
            required
          />
        ))}
      </div>
      <div className='unit-selector'>
        <label><input type="radio" value="inches" name="inputUnit" checked={inputUnit === 'inches'} onChange={() => setInputUnit('inches')} />Inches</label>
        <label><input type="radio" value="feet" name="inputUnit" checked={inputUnit === 'feet'} onChange={() => setInputUnit('feet')} />Feet</label>
        <label><input type="radio" value="meters" name="inputUnit" checked={inputUnit === 'meters'} onChange={() => setInputUnit('meters')} />Meters</label>
      </div>
      <button className='button' onClick={calculateVolumeAndWeight}>Calculate</button>
      {result !== null && (
        <div className='result'>
          <h4>Weight: {result.toFixed(2)} {weightUnit}</h4>
        </div>
      )}
      <div className='unit-selector'>
        <label><input type="radio" value="pounds" name="weightUnit" checked={weightUnit === 'pounds'} onChange={() => setWeightUnit('pounds')} />Pounds</label>
        <label><input type="radio" value="kilograms" name="weightUnit" checked={weightUnit === 'kilograms'} onChange={() => setWeightUnit('kilograms')} />Kilograms</label>
      </div>
    </div>
  );
};

export default Sandbed;

import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import "./style.css"

const EditBlogPost = () => {
  const [posts, setPosts] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedPost, setSelectedPost] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    image: '',
    desc: '',
    content: '',
  });
  const [showDropdown, setShowDropdown] = useState(true); 

  const inputStyle = {marginTop: '5px', width: '100%'};

  useEffect(() => {
    fetch('https://api-lqdwzbqqpa-uc.a.run.app/blogs')
      .then(response => response.json())
      .then(data => {
        setPosts(data);
      })
      .catch(error => console.error('Error fetching posts:', error));
  }, []);

  useEffect(() => {
    if (selectedPost) {
      fetch(`https://api-lqdwzbqqpa-uc.a.run.app/markdown/${selectedPost.id}`)
        .then(response => response.text())
        .then(text => setFormData(prevData => ({ ...prevData, content: text })))
        .catch(error => console.error('Error fetching markdown content:', error));
    }
  }, [selectedPost]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(window.confirm('Do you want to proceed with the changes?')) {
      const updatedPost = {
        title: formData.title,
        image: formData.image,
        desc: formData.desc,
        content: formData.content,
      };

      fetch(`https://api-lqdwzbqqpa-uc.a.run.app/blog/${selectedPost.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('AuthToken')}`,
        },
        body: JSON.stringify(updatedPost),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update the blog post');
        }
        return response.json();
      })
      .then(data => {
        console.log('Success:', data);
        alert('Blog post updated successfully.');
        setSearch('');
        setShowDropdown(true)
        setFormData({
          title: '',
          image: '',
          desc: '',
          content: '',
        });
      })
      .catch(error => {
        console.error('Error updating the blog post:', error);
        alert('Error updating the blog post.');
      });
    }
  };

  const handlePostSelect = (post) => {
    setSearch(post.title);
    setSelectedPost(post);
    setFormData({
      title: post.title,
      image: post.image,
      desc: post.desc
    });
  };

  const filteredPosts = posts.filter((post) => post.title.toLowerCase().includes(search.toLowerCase()));

  return (
    <div>
      <input
        style={{
          width: '90%',
          backgroundColor: 'lightgray',
        }}
        type="text"
        placeholder="Search post title"
        value={search}
        onChange={(e) => {setSearch(e.target.value); setShowDropdown(true); }}
      />
      {showDropdown && (
        <ul style={{ 
            fontWeight: 'bold', 
            height: '100px',
            backgroundColor: 'lightgray',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.2)',
            padding: '5px',
            maxHeight: '30vh',
            width: '88%',
            margin: '0 auto',
            cursor: 'pointer'
        }}>
            {filteredPosts.map((post, index) => (
            <li key={index} onClick={() => {
                setShowDropdown(false);
                handlePostSelect(post);
            }}>
                {post.title}
            </li>
            ))}
        </ul>
      )}
      {selectedPost && (
        <form onSubmit={handleSubmit} style={{display: 'grid', gridTemplateColumns: '1fr', textAlign: 'center', marginTop: '20px' }}>
          <label style={{marginBottom: '10px'}}>
            Title:
            <br />
            <input style={inputStyle} type="text" name="title" value={formData.title} onChange={handleChange} />
          </label>
          <label style={{marginBottom: '10px'}}>
            Image URL:
            <br />
            <input style={inputStyle} type="text" name="image" value={formData.image} onChange={handleChange} />
          </label>
          <label style={{marginBottom: '10px'}}>
            Description:
            <br />
            <textarea style={inputStyle} name="desc" value={formData.desc} onChange={handleChange}></textarea>
          </label>
          <label style={{marginBottom: '10px'}}>
            Content:
            <br />
            <textarea style={inputStyle} name="content" value={formData.content} onChange={handleChange}></textarea>
          </label>
          <h2>Preview</h2>
          <div style={{
            border: '1px solid #ccc',
            padding: '10px',
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '420px',
            maxWidth: '100%',
            }}>
                <div className="markdown-preview">
                    <Markdown>{formData.content}</Markdown>
                </div>
            </div>

          <button style={{ borderRadius: '10px', width: '200px', margin: '0 auto', marginTop: '10px', padding: '7px', cursor: 'pointer'}} type="submit">Submit</button>
        </form>
      )}
    </div>
  );
};

export default EditBlogPost;

import React, { useState } from 'react';
import Markdown from 'react-markdown';
import "./style.css"

const AddBlogPost = () => {
    const [formData, setFormData] = useState({
      title: '',
      image: '',
      author: '',
      desc: '',
      content: '',
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      let blogId;

      await fetch('https://api-lqdwzbqqpa-uc.a.run.app/blogs')
        .then(response => response.json())
        .then(blogs => {
          blogId = +blogs.length + 1; 
        })
        .catch(error => console.error('Error fetching blogs:', error));
    
      const apiEndpoint = 'https://api-lqdwzbqqpa-uc.a.run.app/blog';
      const token = localStorage.getItem('AuthToken');
    
      fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: blogId,
          title: formData.title,
          image: formData.image,
          author: formData.author,
          desc: formData.desc,
          content: formData.content
        }),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Success:', data);
        alert('Blog post added successfully.');
        setFormData({
          title: '',
          image: '',
          author: '',
          desc: '',
          content: '',
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Error adding the blog post.');
      });
    };
    
    return (
      <form onSubmit={handleSubmit} style={{display: 'grid', gridTemplateColumns: '1fr', textAlign: 'center' }}>
        <label style={{marginBottom: '10px'}}>
          Title:
          <br />
          <input style={{marginTop: '5px'}} type="text" name="title" value={formData.title} onChange={handleChange} required />
        </label>
        <label style={{marginBottom: '10px'}}>
          Image:
          <br />
          <input style={{marginTop: '5px'}} type="text" name="image" value={formData.image} onChange={handleChange} required />
        </label>
        <label style={{marginBottom: '10px'}}>
          Author:
          <br />
          <select
            style={{marginTop: '5px'}}
            name="author"
            value={formData.author}
            onChange={handleChange}
            required
          >
            <option value="" disabled>Select Author</option>
            <option value="Billy">Billy</option>
            <option value="Giancarlo">Giancarlo</option>
            <option value="Kristian">Kristian</option>
          </select>
        </label>
        <label style={{marginBottom: '10px'}}>
          Description:
          <br />
          <textarea style={{marginTop: '5px'}} name="desc" value={formData.desc} onChange={handleChange} required ></textarea>
        </label>
        <label style={{marginBottom: '10px'}}>
          Content:
          <br />
          <textarea style={{marginTop: '5px'}} name="content" value={formData.content} onChange={handleChange} ></textarea>
        </label>
        <h2>Preview</h2>
          <div style={{
            border: '1px solid #ccc',
            padding: '10px',
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '420px',
            maxWidth: '100%',
            }}>
                <div className="markdown-preview">
                    <Markdown>{formData.content}</Markdown>
                </div>
            </div>
        <button style={{ borderRadius: '10px', width: '200px', margin: '0 auto', marginTop: '10px', padding: '7px', cursor: 'pointer'}} type="submit">Submit</button>
      </form>
    );
  };
  
export default AddBlogPost;
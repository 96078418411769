import React, { useState } from "react";
import AddBlogPost from '../components/AddBlogPost';
import EditBlogPost from '../components/EditBlogPost';
import AddDictionary from '../components/AddDictionary';
import EditDictionary from '../components/EditDictionary';
import AddEvent from '../components/AddEvent';
import EditEvent from '../components/EditEvent';
import SignIn from '../components/SignIn';
import AdminMenu from '../components/AdminMenu';
import "./Admin.css"

const Admin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [adminAction, setAdminAction] = useState('');

  const renderAdminTask = () => {
    switch(adminAction) {
      case 'addBlogPost':
        return <AddBlogPost />;
      case 'editBlogPost':
        return <EditBlogPost />;
      case 'addDictionary':
        return <AddDictionary />;
      case 'editDictionary':
        return <EditDictionary />;
      case 'addEvent':
        return <AddEvent />;
      case 'editEvent':
        return <EditEvent />;
      default:
        return (
          <div className="button-grid">
            <button onClick={() => setAdminAction('addBlogPost')}>Add Blog Post</button>
            <button onClick={() => setAdminAction('addDictionary')}>Add Dictionary</button>
            <button onClick={() => setAdminAction('addEvent')}>Add Event</button>
            <button onClick={() => setAdminAction('editBlogPost')}>Edit Blog Post</button>
            <button onClick={() => setAdminAction('editDictionary')}>Edit Dictionary</button>
            <button onClick={() => setAdminAction('editEvent')}>Edit Event</button>
          </div>
        );
    }
  };

  return (
    <div className="admin-container">
      {!isLoggedIn ? (
        <SignIn setIsLoggedIn={setIsLoggedIn} />
      ) : (
        <div>
          <AdminMenu setAdminAction={setAdminAction} />
          {renderAdminTask()}
        </div>
      )}
    </div>
  );
};

export default Admin;
import React, { useState } from 'react';
import '../styles.css';

const Target = () => {
  const [initialSalinity, setInitialSalinity] = useState('');
  const [finalSalinity, setFinalSalinity] = useState('');
  const [volume, setVolume] = useState('');
  const [volumeReplaced, setVolumeReplaced] = useState('');
  const [replacementSalinity, setReplacementSalinity] = useState(null);
  const [error, setError] = useState(null);

  const calculateReplacementSalinity = () => {
    if (!initialSalinity || !finalSalinity || !volume || !volumeReplaced) {
      setError('Please fill out all required fields');
      return;
    }

    const S0 = parseFloat(initialSalinity);
    const Sf = parseFloat(finalSalinity);
    const V = parseFloat(volume);
    const r = parseFloat(volumeReplaced);

    const Sn = (Sf * V - S0 * (V - r)) / r;
    setReplacementSalinity(Sn);
    setError(null);
  };

  return (
    <div>
        <p>Determine the salinity needed in the replacement water to achieve a specific final salinity.</p>
      <div>
        <input className='input' type="number" placeholder='Initial Salinity' value={initialSalinity} onChange={e => setInitialSalinity(e.target.value)} />
      </div>
      <div>
        <input className='input' type="number" placeholder='Final Salinity' value={finalSalinity} onChange={e => setFinalSalinity(e.target.value)} />
      </div>
      <div>
        <input className='input' type="number" placeholder='Total Volume' value={volume} onChange={e => setVolume(e.target.value)} />
      </div>
      <div>
        <input className='input' type="number" placeholder='Volume Replaced' value={volumeReplaced} onChange={e => setVolumeReplaced(e.target.value)} />
      </div>
      <button className='button' onClick={calculateReplacementSalinity}>Calculate</button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {replacementSalinity !== null && <div className='calculation'>Replacement Salinity: {replacementSalinity.toFixed(2)}</div>}
    </div>
  );
};

export default Target;
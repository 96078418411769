import React from "react";
import aiptasia from '../images/aiptasia.png';
import './Support.css'; 

const Support = () => {
  return (
    <div className="support-container">
      <h1 className="support-header">
        Support Our Cause
      </h1>
      <p className="support-text">
        Your support matters. Help us expand our initiatives by donating or purchasing our exclusive merchandise. Every contribution makes a difference.
      </p>
      <div className="support-section">
        <h2>Donate</h2>
        <p>Choose your preferred method to make a donation and support our educational and environmental efforts.</p>
        <div className="button-section">
          <a href='https://ko-fi.com/E1E5PMAU9' target='_blank' rel="noreferrer" style={{ textDecoration: 'none'}}>
            <button className="support-button">
              <img height='24' style={{marginRight: '5px'}} src={aiptasia} border='0' alt='Buy Giancarlo Aiptasia' />
              Buy Giancarlo Aiptasia
            </button>
          </a>
          <a href="https://www.buymeacoffee.com/sliceofthereef" target="_blank" rel="noreferrer" style={{ textDecoration: 'none'}}>
            <button className="support-button">🍵 Buy Billy a Tea</button>
          </a>
        </div>
      </div>
      <div className="support-section">
        <h2>Merchandise</h2>
        <p>Explore our range of exclusive merchandise and show your support in style.</p>
        <iframe 
          className="support-iframe" 
          src="https://embed.creator-spring.com/widget?slug=sliceofthereef&per=20&currency=&page=1&layout=carousel-wide&theme=light" 
          title="sliceofthereef Merch store powered by Spring" 
          style={{ borderRadius: '24px', border: 'none', width: '100%', height: '420px' }} 
          data-reactroot="">
        </iframe>
      </div>
    </div>
  );
};

// Export the component for use in other parts of the application
export default Support;

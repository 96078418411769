import React, { useState, useEffect } from "react";
import './Blog.css';
import { Link } from 'react-router-dom';
import loadingImage from '../images/Fish.gif'; 

const Blog = () => {
  const [visiblePosts, setVisiblePosts] = useState(3);
  const [disableButton, setDisableButton] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetch('https://api-lqdwzbqqpa-uc.a.run.app/blogs')
      .then(response => response.json())
      .then(data => {
        setBlogPosts(data);
      })
      .catch(error => console.error('Error fetching blog posts:', error));
  }, []);

  const filteredBlogPosts = blogPosts.filter(
    item => item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.desc.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderBlogPost = (post) => {
    const urlTitle = post.title.replace(/\s+/g, '-').toLowerCase();
    return (
      <Link to={`/blog/${urlTitle}`} key={post.id} style={{ textDecoration: 'none', color: 'black' }}>
        <div className="blog-post">
          <img src={post.image} alt={post.title} />
          <div className="blog-post-content">
            <h3>{post.title}</h3>
            <span style={{fontSize: "small", marginTop: "-10px"}}>by {post.author}</span>
            <p>{post.desc}</p>
          </div>
        </div>
      </Link>
    );
  };

  const handleLoadMore = () => {
    if (visiblePosts >= filteredBlogPosts.length) {
      setDisableButton(true);
      return;
    }
    setVisiblePosts(prevState => prevState + 6);
  };

  if (!blogPosts.length) {
    return (
      <div className="loading-container">
        <img src={loadingImage} alt="Loading..." className="loading-image" />
      </div>
    );
  }

  const clearSearch = () => {
    setSearchTerm("");
  };

  return (
    <div className="blog-container">
      <h1 className="blog-title">The Aquarist Journal</h1>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search Blogs"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        {searchTerm && <button className="clear-button" onClick={clearSearch}>X</button>}
      </div>
      <div className="blog-posts-container">
        {filteredBlogPosts.slice(0, visiblePosts).map(renderBlogPost)}
        <button className="load-more-button" onClick={handleLoadMore} disabled={disableButton} style={{ marginBottom: '30px' }}>
          {disableButton ? 'No More Posts' : 'Load More'}
        </button>
      </div>
    </div>
  );
};

export default Blog;

import React, { useState, useEffect } from 'react';
import saltData from '../data/salinity.json';
import '../styles.css';

const Salt = () => {
  const [volume, setVolume] = useState('');
  const [volumeUnit, setVolumeUnit] = useState('gallons');
  const [salinityUnit, setSalinityUnit] = useState('sg');
  const [currentSalinity, setCurrentSalinity] = useState('');
  const [finalSalinity, setFinalSalinity] = useState('');
  const [selectedSalt, setSelectedSalt] = useState(saltData[0].inputs);
  const [outputUnit, setOutputUnit] = useState('pounds');
  const [originalResult, setOriginalResult] = useState(null);
  const [convertedResult, setConvertedResult] = useState(null);
  const [error, setError] = useState(null);

  const litersToGallons = (liters) => liters * 0.264172;
  const pptToSg = (ppt) => 1.00003 + (ppt * 0.00075);

  const convertResult = (result, unit) => {
    switch (unit) {
      case 'grams':
        return result * 453.592;
      case 'kilograms':
        return result * 0.453592;
      default:
        return result;
    }
  };

  const handleCalculate = () => {
    const selectedSaltData = saltData.find(salt => salt.inputs === selectedSalt);
    if (!selectedSaltData) return;

    if (salinityUnit === 'sg' && currentSalinity < 1) {
      setOriginalResult(null);
      setError('Specific gravity must be 1 or more.');
      return;
    }

    let convertedVolume = volumeUnit === 'liters' ? litersToGallons(parseFloat(volume)) : parseFloat(volume);
    let convertedCurrentSalinity = salinityUnit === 'ppt' ? pptToSg(parseFloat(currentSalinity)) : parseFloat(currentSalinity);
    let convertedFinalSalinity = salinityUnit === 'ppt' ? pptToSg(parseFloat(finalSalinity)) : parseFloat(finalSalinity);

    let result = convertedVolume * (convertedFinalSalinity - convertedCurrentSalinity) * selectedSaltData.weight / 0.001;

    setError(null);
    setOriginalResult(result);
    result = convertResult(result, outputUnit);
    setConvertedResult(result);
  };

  useEffect(() => {
    if (originalResult !== null) {
      let convertedWeight = originalResult;

      if (outputUnit === 'grams') {
        convertedWeight *= 453.592;
      } else if (outputUnit === 'kilograms') {
        convertedWeight *= 0.453592;
      } else {
        convertedWeight = originalResult;
      }

      setConvertedResult(convertedWeight);
    }
  }, [outputUnit, originalResult]);

  return (
    <div>
      <div>
        <input className='input' type="number" placeholder='Volume' value={volume} onChange={e => setVolume(e.target.value)} />
      </div>
      <div>
        <label>
          <input type="radio" value="gallons" name="volumeUnit" checked={volumeUnit === 'gallons'} onChange={() => setVolumeUnit('gallons')} />
          Gallons
        </label>
        <label>
          <input type="radio" value="liters" name="volumeUnit" checked={volumeUnit === 'liters'} onChange={() => setVolumeUnit('liters')} />
          Liters
        </label>
      </div>
      <div>
        <input className='input' type="number" placeholder='Current Salinity' value={currentSalinity} onChange={e => {
          setCurrentSalinity(e.target.value);
          setError('');
        }} />
      </div>
      <div>
        <input className='input' type="number" placeholder='Final Salinity' value={finalSalinity} onChange={e => setFinalSalinity(e.target.value)} />
      </div>
      <div>
        <label>
          <input type="radio" value="sg" name="salinityUnit" checked={salinityUnit === 'sg'} onChange={() => setSalinityUnit('sg')} />
          SG
        </label>
        <label>
          <input type="radio" value="ppt" name="salinityUnit" checked={salinityUnit === 'ppt'} onChange={() => setSalinityUnit('ppt')} />
          PPT
        </label>
      </div>
      <div>
        <label>Choose Salt: </label>
        <select className='dropdown' value={selectedSalt} onChange={e => setSelectedSalt(e.target.value)}>
          {saltData.map((salt, index) => (
            <option key={index} value={salt.inputs}>{salt.name}</option>
          ))}
        </select>
      </div>
      <button className='button' onClick={handleCalculate}>Calculate</button>
      <div>
        <label>
          <input type="radio" value="pounds" name="outputUnit" checked={outputUnit === 'pounds'} onChange={() => setOutputUnit('pounds')} />
          Pounds
        </label>
        <label>
          <input type="radio" value="grams" name="outputUnit" checked={outputUnit === 'grams'} onChange={() => setOutputUnit('grams')} />
          Grams
        </label>
        <label>
          <input type="radio" value="kilograms" name="outputUnit" checked={outputUnit === 'kilograms'} onChange={() => setOutputUnit('kilograms')} />
          Kilograms
        </label>
      </div>
      {error !== null && (
        <div>
          <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>
        </div>
      )}
      {convertedResult !== null && (
        <div>
          <p className='calculation'>Salt Needed: {convertedResult.toFixed(2)} {outputUnit}</p>
        </div>
      )}
    </div>
  );
};

export default Salt;

import React, { useState } from 'react';

const SignIn = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSignIn = () => {
    const loginDetails = {
      email,
      password
    };

    fetch('https://api-lqdwzbqqpa-uc.a.run.app/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginDetails),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Login failed');
      }
      return response.json();
    })
    .then(data => {
      localStorage.setItem('AuthToken', data.token);
      setIsLoggedIn(true);
    })
    .catch(error => {
      setError('Failed to sign in. Please check your credentials and try again.');
      console.error('SignIn error', error);
    });
  };

  return (
    <div>
      <h2>Admin Sign-In</h2>
      <input
        type="email"
        placeholder="Enter email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{marginBottom: '5px'}}
      />
      <br />
      <input
        type="password"
        placeholder="Enter password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{marginBottom: '15px'}}
      />
      <br />
      <button onClick={handleSignIn}>Sign In</button>
      {error && <p>{error}</p>}
    </div>
  );
};

export default SignIn;

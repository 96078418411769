import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import PinterestIcon from '@mui/icons-material/Pinterest';
import ShareIcon from '@mui/icons-material/Share';
import CircularProgress from '@mui/material/CircularProgress'
import '../pages/Blog.css'

const BlogPost = () => {
  const { title } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [markdownContent, setMarkdownContent] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the blog post data from the backend API using the title
    fetch(`https://api-lqdwzbqqpa-uc.a.run.app/blogs`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Blog posts not found.');
        }
        return response.json();
      })
      .then(blogs => {
        const matchedBlog = blogs.find(blog => blog.urlTitle === title);
        setBlogPost(matchedBlog);
        return fetch(`https://api-lqdwzbqqpa-uc.a.run.app/markdown/${matchedBlog.id}`);
      })
      .then(response => response.text())
      .then(text => {
        setMarkdownContent(text);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blog post:', error);
        setLoading(false);
      });
  }, [title]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!blogPost) {
    return <div>Blog post not found.</div>;
  }

  const shareToSocialMedia = (platform) => {
    const postUrl = window.location.href;
  
    let shareUrl;
  
    switch(platform) {
      case 'twitter':
        shareUrl = `https://twitter.com/share?&text=${blogPost.title}&url=${postUrl}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer.php?u=${postUrl}`;
        break;
      case 'pinterest':
        shareUrl = `https://pinterest.com/pin/create/button/?url=${postUrl}&media=${blogPost.image}&description=${blogPost.title}`;
        break;
      case 'link':
        navigator.clipboard.writeText(postUrl);
        alert('Link copied to clipboard');
        return;
      default:
        return;
    }
    window.open(shareUrl, '_blank').focus();
  }
  

  return (
    <div className='blogPost' style={{ width: "80%", padding: '30px', margin: '0 auto 20px auto', borderRadius: '12px', fontFamily: 'Roboto', backgroundColor: 'white', boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.2)'}}>
      {blogPost ? (
        <>
          <h1 className='blogTitle'>{blogPost.title}</h1>
          <p className='blogDesc'>{blogPost.desc}</p>
          <hr className="divider" />
          <div className='author-container'>
            <div className='author-details'>
              <img alt={blogPost.author} src={blogPost.authorImg} />
              <p>{blogPost.author}</p>
              <a href={blogPost.socialLink} target="_blank" rel="noreferrer">
                <InstagramIcon sx={{fontSize: 'large', marginTop: '3px'}} />
              </a>
            </div>
            <div className='date-details'>
              <p>Published: {blogPost.datePub}</p>
            </div>
          </div>
          <hr className="divider" />
          <ReactMarkdown>{markdownContent}</ReactMarkdown>
          <hr className="divider" />
          <h2 className='shareTitle'>Share</h2>
          <div className="share-widget">
            <button onClick={() => shareToSocialMedia('twitter')}><TwitterIcon sx={{marginTop: '5px'}} /></button>
            <button onClick={() => shareToSocialMedia('facebook')}><FacebookIcon sx={{marginTop: '5px'}} /></button>
            <button onClick={() => shareToSocialMedia('pinterest')}><PinterestIcon sx={{marginTop: '5px'}} /></button>
            <button onClick={() => shareToSocialMedia('link')}><ShareIcon sx={{marginTop: '5px'}} /></button>
          </div>
        </>
      ) : (
        <p>Blog post not found.</p>
      )}
    </div>
  );
};

export default BlogPost;

import React from 'react';
import ReturnPump from './Equipment/ReturnPump';
import HydrometerCalibration from './Equipment/HydrometerCalibration';
import FilterMedia from './Equipment/FilterMedia';
import Conversions from './Equipment/Conversions';

const Equipment = ({ equipmentType }) => {
  const renderCalculator = () => {
    switch (equipmentType) {
      case 'returnPump':
        return <ReturnPump />;
      case 'hydrometerCalibration':
        return <HydrometerCalibration />;
      case 'filterMedia':
        return <FilterMedia />;
      case 'conversions':
        return <Conversions />;
      default:
        return <p>Please select an equipment type.</p>;
    }
  };

  return (
    <div className="equipment-calculator">
      {renderCalculator()}
    </div>
  );
};

export default Equipment;

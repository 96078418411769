import React, {useState, useEffect} from "react";
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import CircleIcon from '@mui/icons-material/Circle';
import "./Home.css";
import loadingImage from '../images/Fish.gif'; 
import dayjs from 'dayjs';

const Home = () => {
  const [latestBlog, setLatestBlog] = useState(null);
  const [eventsData, setEventsData] = useState([]);

  useEffect(() => {
    fetch('https://api-lqdwzbqqpa-uc.a.run.app/blogs')
      .then(response => response.json())
      .then(blogs => {
        setLatestBlog(blogs[0]);
      })
      .catch(error => console.error('Error fetching blogs:', error));

    fetch('https://api-lqdwzbqqpa-uc.a.run.app/events')
      .then(response => response.json())
      .then(events => {
        const sortedEvents = events.sort((a, b) => a.episode - b.episode);
      setEventsData(sortedEvents);
      })
      .catch(error => console.error('Error fetching events:', error));
  }, []);
  
  function to12HourTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
  
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
  
    return date.toLocaleTimeString('en-US', options);
  }
  
  const getEndTime = (startDate, startTime) => {  
    const dateTimeString = `${startDate}T${startTime}`;
    const dateTime = new Date(dateTimeString);

    const endTime = `${dateTime.getHours() + 1}:${(dateTime.getMinutes() < 10 ? '0' : '') + dateTime.getMinutes()}`;
    return endTime;
  };  

  if (!latestBlog) {
    return (
      <div className="loading-container">
        <img src={loadingImage} alt="Loading..." className="loading-image" />
      </div>
    );
  }

  const eventSlides = eventsData.map(event => (
    <div key={event.episode} className="event-slide">
      <div className="event-details">
        <h3>{event.title}</h3>
        <p>{event.desc}</p>
        <p>Date: {dayjs(event.date).format('MMMM D, YYYY')}</p>
        <p>Time: {to12HourTime(event.time)} PST</p>
        <a href={event.link} target="_blank" rel="noopener noreferrer">Listen Here</a>
      </div>
      <img src={event.image} alt={event.title} />
      <div className="calendar-buttons">
        <AddToCalendarButton
          name={event.title}
          description={event.desc}
          startDate={event.date}
          startTime={event.time}
          endTime={getEndTime(event.date, event.time)}
          timeZone="America/Los_Angeles"
          location={event.link}
          options={['Apple','Google','iCal','Outlook.com','Yahoo']}
          buttonsList
          hideTextLabelButton
          buttonStyle="round"
          lightMode="bodyScheme"
        ></AddToCalendarButton>
        <br />
      </div>
    </div>
  ));  
  
  return (
    <div className="home-container">
      <h1 className="home-title">Welcome to our Slice of the Reef!</h1>

      <div className="featured-content" style={{ backgroundImage: `url(${latestBlog.image})`}}>
        
        <div className="text">
          <h4>Featured</h4>
          <h3>{latestBlog.title}</h3>
          <p>{latestBlog.desc}</p>
          <button>
            <a href={`/blog/${latestBlog.urlTitle}`} className="blog-link">Read More</a>
          </button>
        </div>
        <div className="darken"></div>
      </div>

        <div className="content">
          <div className="event-container">
            <h2 style={{ textDecoration: 'underline'}}>Upcoming Live Recordings</h2>
            <div className="event-carousel">
              {eventSlides.map((slide, index) => (
                <React.Fragment key={slide.key}>
                  <input 
                    type="radio" 
                    id={`slide${index + 1}`} 
                    name="slides" 
                    className="slide-input" 
                    defaultChecked={index === 0} 
                  />
                  <div className="slide">{slide}</div>
                </React.Fragment>
              ))}

              <div className="slider-controls">
                {eventSlides.map((_, index) => (
                  <label key={index} htmlFor={`slide${index + 1}`} className="slider-label"><CircleIcon className="label-icon" /></label>
                ))}
              </div>
            </div>
          </div>
          <div className="podcast-content">
            <p>Catch our podcast live on the official <a href="https://discord.gg/X3v4Xwftn3" target="_blank" rel="noreferrer" className="discord-link">Slice of the Reef Discord</a>. Join us for an engaging Q&A session afterward!</p>
            <iframe id="player_iframe" src="https://www.buzzsprout.com/2197229?client_source=large_player&iframe=true&referrer=https%3A%2F%2Fwww.buzzsprout.com%2F2197229%2Fpodcast%2Fembed"
              loading="lazy"
              title="Slice of the Reef">
            </iframe>
          </div>
        </div>
    </div>
  );
};

export default Home;

import React, { useState } from 'react';
import '../styles.css';

const ReturnPump = () => {
  const [volume, setVolume] = useState('');
  const [volumeUnit, setVolumeUnit] = useState('gallons');
  const [turnoverRate, setTurnoverRate] = useState(1);
  const [result, setResult] = useState(null);

  const calculateReturnPump = () => {
    let volumeValue = parseFloat(volume);
    if (isNaN(volumeValue)) return;

    let requiredFlowRate = volumeValue * turnoverRate;
    setResult(requiredFlowRate.toFixed(2));
  };

  return (
    <div className="equipment-calculator">
      <h2>Return Pump Calculator</h2>
      <div>
        <input
          className='input'
          type="number"
          placeholder={`Tank Volume`}
          value={volume}
          onChange={e => setVolume(e.target.value)}
        />
        <div className='unit-selector' style={{ marginBottom: "20px"}}>
          <label>
            <input type="radio" value="gallons" name="volumeUnit"
                   checked={volumeUnit === 'gallons'} onChange={() => setVolumeUnit('gallons')} />
            Gallons
          </label>
          <label>
            <input type="radio" value="liters" name="volumeUnit"
                   checked={volumeUnit === 'liters'} onChange={() => setVolumeUnit('liters')} />
            Liters
          </label>
        </div>
      </div>
      <div className="dropdown-container">
        <label>Desired Turnover Rate: {turnoverRate}x</label>
        <input
          className='slider'
          type="range"
          min="1"
          max="15"
          value={turnoverRate}
          onChange={e => setTurnoverRate(e.target.value)}
        />
      </div>
      <button className='button' onClick={calculateReturnPump}>Calculate</button>
      {result !== null && (
        <div>
          <p className='calculation'>Required Pump Size: {result} {volumeUnit === 'gallons' ? 'GPH' : 'LPH'}</p>   
        </div>
      )}
      <p style={{ fontSize: 'smaller', marginTop: '20px' }}>
      Note: This calculation excludes head loss, and actual pump performance may vary. Consider these factors when selecting a return pump.
          </p>
    </div>
  );
};

export default ReturnPump;

import React, { useState } from 'react';
import filterMediaData from '../data/filterMedia.json';
import '../styles.css';

const FilterMedia = () => {
  const [volume, setVolume] = useState('');
  const [volumeUnit, setVolumeUnit] = useState('gallons');
  const [selectedType, setSelectedType] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [recommendation, setRecommendation] = useState('');
  const [messages, setMessages] = useState([]);

  const litersToGallons = (liters) => liters * 0.264172;

  const calculateMediaAmount = () => {
    const mediaDetails = filterMediaData.find(media => media.Type === selectedType && media.Brand === selectedBrand);
    if (!mediaDetails) return;

    const volumeInGallons = volumeUnit === 'liters' ? litersToGallons(parseFloat(volume)) : parseFloat(volume);
    const recommendationAmount = volumeInGallons * mediaDetails.AmountPerUSGallon;

    setRecommendation(`${recommendationAmount.toFixed(2)} ${mediaDetails.Units}`);
    setMessages(mediaDetails.Messages);
  };

  const mediaTypes = Array.from(new Set(filterMediaData.map(media => media.Type)));
  const filteredBrands = filterMediaData.filter(media => media.Type === selectedType);

  return (
    <div className="filter-media-calculator">
      <h2>Filter Media Calculator</h2>
      <div>
        <input
          className='input'
          type="number"
          placeholder="Volume"
          value={volume}
          onChange={e => setVolume(e.target.value)}
        />
        <label>
          <input
            type="radio"
            value="gallons"
            checked={volumeUnit === 'gallons'}
            onChange={() => setVolumeUnit('gallons')}
          />
          Gallons
        </label>
        <label>
          <input
            type="radio"
            value="liters"
            checked={volumeUnit === 'liters'}
            onChange={() => setVolumeUnit('liters')}
          />
          Liters
        </label>
      </div>
      <div>
        <label>Choose Media Type: </label>
        <select
          className='dropdown'
          value={selectedType}
          onChange={(e) => {
            setSelectedType(e.target.value);
            setSelectedBrand('');
            setRecommendation('');
            setMessages([]);
          }}
        >
          <option value="">Select a Media Type</option>
          {mediaTypes.map((type, index) => (
            <option key={index} value={type}>{type}</option>
          ))}
        </select>
      </div>
      {selectedType && (
        <div>
          <label>Choose Brand: </label>
          <select
            className='dropdown'
            value={selectedBrand}
            onChange={e => setSelectedBrand(e.target.value)}
            disabled={!selectedType}
          >
            <option value="">Select a Brand</option>
            {filteredBrands.map((brand, index) => (
              <option key={index} value={brand.Brand}>{brand.Brand}</option>
            ))}
          </select>
        </div>
      )}
      <button className='button' onClick={calculateMediaAmount}>Calculate</button>
      {recommendation && (
        <div>
          <p className='calculation'>Recommended Amount: {recommendation}</p>
          {messages.map((message, index) => (
            <p key={index} style={{fontSize: 'smaller', marginTop: '0.5em'}}>
              {message}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterMedia;

import React, { useState, useEffect } from 'react';
import calculationsData from './data/volume.json'; 
import './styles.css';

const Volume = () => {
  const [selectedShape, setSelectedShape] = useState(calculationsData[0].shape);
  const [inputs, setInputs] = useState({});
  const [result, setResult] = useState(null);
  const [inputUnit, setInputUnit] = useState('meters'); 
  const [outputUnit, setOutputUnit] = useState('gallons');

  // Function to handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: parseFloat(value)
    }));
  };

  const handleSelectChange = (event) => {
    setSelectedShape(event.target.value);
    setInputs({});
    setResult(null);
  };


  // Function to calculate volume
  const calculateVolume = () => {
    const calculation = calculationsData.find((calc) => calc.shape === selectedShape);
    const formula = new Function(...calculation.inputs, `return ${calculation.formula};`);
    let calculatedVolume = formula(...calculation.inputs.map(input => inputs[input]));

    // Convert the calculated volume based on input and output units
    if (inputUnit === 'inches') {
      calculatedVolume /= 231; // Convert cubic inches to gallons
    } else if (inputUnit === 'feet') {
      calculatedVolume *= 7.48052; // Convert cubic feet to gallons
    } else {
      calculatedVolume *= 264.172; // Convert cubic meters to gallons
    }

    if (outputUnit === 'liters') {
      calculatedVolume *= 3.78541; // Convert gallons to liters
    }

    setResult(calculatedVolume);
  };

  const getImagePath = (shape) => {
    try {
      return require(`./images/volume/${shape}.png`);
    } catch {
      return null;
    }
  };

  // Recalculate when the output unit changes
  useEffect(() => {
    if (result !== null) {
      calculateVolume();
    }
  }, [outputUnit]);

  return (
    <div>
      <select className='dropdown' value={selectedShape} onChange={handleSelectChange}>
        {calculationsData.map((calc) => (
          <option key={calc.shape} value={calc.shape}>
            {calc.shape.charAt(0).toUpperCase() + calc.shape.slice(1)}
          </option>
        ))}
      </select>
      <div className="calculator-image">
        {getImagePath(selectedShape) && (
          <img src={getImagePath(selectedShape)} alt={selectedShape} />
        )}
      </div>
      <div>
        {calculationsData.find((calc) => calc.shape === selectedShape).inputs.map((input) => (
          <input
            className='input'
            key={input}
            type="number"
            name={input}
            value={inputs[input] || ''}
            onChange={handleInputChange}
            placeholder={input.charAt(0).toUpperCase() + input.slice(1)}
            required
          />
        ))}
      </div>
      <div className='unit-selector'>
        <label>
          <input type="radio" value="inches" name="inputUnit" 
                 checked={inputUnit === 'inches'} onChange={() => setInputUnit('inches')} />
          Inches
        </label>
        <label>
          <input type="radio" value="feet" name="inputUnit" 
                 checked={inputUnit === 'feet'} onChange={() => setInputUnit('feet')} />
          Feet
        </label>
        <label>
          <input type="radio" value="meters" name="inputUnit" 
                 checked={inputUnit === 'meters'} onChange={() => setInputUnit('meters')} />
          Meters
        </label>
      </div>

     
      <button className='button' onClick={calculateVolume}>Calculate</button>

      <div className='unit-selector'>
            <label>
            <input type="radio" value="gallons" name="outputUnit" 
                    checked={outputUnit === 'gallons'} onChange={() => setOutputUnit('gallons')} />
            Gallons
            </label>
            <label>
            <input type="radio" value="liters" name="outputUnit" 
                    checked={outputUnit === 'liters'} onChange={() => setOutputUnit('liters')} />
            Liters
            </label>
        </div>

        {result !== null && (
        <div className='result'>
          <h4 className='calculation'>Volume: {result.toFixed(2)} {outputUnit}</h4>
        </div>
      )}
    </div>
  );
};

export default Volume;

import React, { useState } from 'react';
import '../styles.css';

const Copper = () => {
  const [aquariumSize, setAquariumSize] = useState('');
  const [currentCopper, setCurrentCopper] = useState('');
  const [desiredCopper, setDesiredCopper] = useState('');
  const [copperSolutionVolume, setCopperSolutionVolume] = useState(null);
  const [error, setError] = useState(null);

  const calculateCopperSolution = () => {
    if (!aquariumSize || !currentCopper || !desiredCopper) {
      setError('Please fill out all required fields');
      return;
    }

    const V = parseFloat(aquariumSize);
    const C_current = parseFloat(currentCopper);
    const C_desired = parseFloat(desiredCopper);

    const volumeNeeded = (C_desired - C_current) * V * 0.59;
    setCopperSolutionVolume(volumeNeeded);
    setError(null);
  };

  return (
    <div>
      <p>Using Copper Power product to adjust the copper level in your aquarium.</p>
      <div>
        <input className='input' type="number" placeholder='Volume (Gallons)' value={aquariumSize} onChange={e => setAquariumSize(e.target.value)} />
      </div>
      <div>
        <input className='input' type="number" placeholder='Current Copper (PPM)' value={currentCopper} onChange={e => setCurrentCopper(e.target.value)} />
      </div>
      <div>
        <input className='input' type="number" placeholder='Desired Copper (PPM)' value={desiredCopper} onChange={e => setDesiredCopper(e.target.value)} />
      </div>
      <button className='button' onClick={calculateCopperSolution}>Calculate</button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {copperSolutionVolume !== null && <div className='calculation'>Volume of Copper Solution Needed: {copperSolutionVolume.toFixed(2)} mL</div>}
    </div>
  );
};

export default Copper;
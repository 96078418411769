import React, {useState} from 'react';
import "../pages/Admin.css"

const AdminMenu = ({ setAdminAction }) => {
  const [selectedAction, setSelectedAction] = useState("");

  return (
    <div className='dashboard'>
      <h2>Admin Dashboard</h2>
      <select value={selectedAction} onChange={e => {
        setSelectedAction(e.target.value);
        setAdminAction(e.target.value);
      }}>
        <option value="" disabled>Select an action</option>
        <option value="addBlogPost">Add a Blogpost</option>
        <option value="addDictionary">Add to Dictionary</option>
        <option value="addEvent">Add an Event</option>
        <option value="editBlogPost">Edit a Blogpost</option>
        <option value="editDictionary">Edit Dictionary</option>
        <option value="editEvent">Edit an Event</option>
      </select>
    </div>
  );
};

export default AdminMenu;

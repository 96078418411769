import React, { useState } from 'react';
import '../styles.css';

const Conversions = () => {
  const [value, setValue] = useState('');
  const [conversionType, setConversionType] = useState('volume');
  const [fromUnit, setFromUnit] = useState('gallons');
  const [toUnit, setToUnit] = useState('liters');
  const [result, setResult] = useState(null);

  const conversionRates = {
    volume: {
      gallons: { liters: 3.78541, cubicFeet: 0.133681 },
      liters: { gallons: 0.264172, cubicFeet: 0.0353147 },
      cubicFeet: { gallons: 7.48052, liters: 28.3168 },
    },
    weight: {
      pounds: { kilograms: 0.453592, grams: 453.592, ounces: 16 },
      kilograms: { pounds: 2.20462, grams: 1000, ounces: 35.274 },
      grams: { pounds: 0.00220462, kilograms: 0.001, ounces: 0.035274 },
      ounces: { pounds: 0.0625, kilograms: 0.0283495, grams: 28.3495 },
    },
    salinity: {
      ppt: { sg: (ppt) => 1 + 0.000753 * ppt },
      sg: { ppt: (sg) => (sg - 1) / 0.000753 },
    },
  };

  const convert = () => {
    const inputValue = parseFloat(value);
    if (isNaN(inputValue)) return;

    let convertedValue;
    if (conversionType === 'salinity') {
      convertedValue = conversionRates[conversionType][fromUnit][toUnit](inputValue).toFixed(4);
    } else {
      convertedValue = (inputValue * conversionRates[conversionType][fromUnit][toUnit]).toFixed(2);
    }

    setResult(convertedValue);
  };

  const handleFromUnitChange = (e) => {
    setFromUnit(e.target.value);
    setResult(null); // Clear result on unit change
  };

  const handleToUnitChange = (e) => {
    setToUnit(e.target.value);
    setResult(null); // Clear result on unit change
  };

  const handleConversionTypeChange = (e) => {
    const newConversionType = e.target.value;
    setConversionType(newConversionType);
    setFromUnit(Object.keys(conversionRates[newConversionType])[0]);
    setToUnit(Object.keys(conversionRates[newConversionType])[1]);
    setValue('');
    setResult(null);
  };

  const handleValueChange = (e) => {
    const newValue = e.target.value;
    if (conversionType === 'salinity' && fromUnit === 'sg' && newValue < 1) {
      setValue('');
    } else if (newValue < 0) {
      setValue('');
    } else {
      setValue(newValue);
    }
    setResult(null); // Clear result on value change
  };

  const swapUnits = () => {
    const temp = fromUnit;
    setFromUnit(toUnit);
    setToUnit(temp);
    setResult(null); // Clear result on unit swap
  };

  return (
    <div className="equipment-calculator">
      <h2>Conversions Calculator</h2>
      <div>
        <select className='dropdown' value={conversionType} onChange={handleConversionTypeChange}>
          <option value="volume">Volume</option>
          <option value="weight">Weight</option>
          <option value="salinity">Salinity</option>
        </select>
      </div>
      <div>
        <input
          className='input'
          type="number"
          placeholder="Value"
          value={value}
          onChange={handleValueChange}
          min={conversionType === 'salinity' && fromUnit === 'sg' ? '1' : '0'}
        />
        <div className="unit-selector">
          <select className='dropdown' value={fromUnit} onChange={handleFromUnitChange}>
            {Object.keys(conversionRates[conversionType]).map(unit => (
              <option key={unit} value={unit} disabled={unit === toUnit}>{unit}</option>
            ))}
          </select>
          <button className="conversion-arrow" onClick={swapUnits}>⇄</button>
          <select className='dropdown' value={toUnit} onChange={handleToUnitChange}>
            {Object.keys(conversionRates[conversionType]).map(unit => (
              <option key={unit} value={unit} disabled={unit === fromUnit}>{unit}</option>
            ))}
          </select>
        </div>
      </div>
      <button className='button' onClick={convert}>Calculate</button>
      {result !== null && (
        <div>
          <p className='calculation'>Converted Value: {result} {toUnit}</p>
        </div>
      )}
      {conversionType === 'salinity' && (
        <p className="note" style={{ fontSize: 'smaller' }}>
          Note that relative specific gravity (sg) measurements are temperature-dependent. This calculator assumes a standard temperature of 25°C (77°F).
        </p>
      )}
    </div>
  );
};

export default Conversions;

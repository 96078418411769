import React, { useState } from 'react';
import '../styles.css';

const HydrometerCalibration = () => {
  const [specificGravityRead, setSpecificGravityRead] = useState('');
  const [unitCalibrationTempF, setUnitCalibrationTempF] = useState('');
  const [actualTempF, setActualTempF] = useState('');
  const [correctedReading, setCorrectedReading] = useState(null);

  const calculateCorrection = () => {
    const specificGravity = parseFloat(specificGravityRead);
    const calibrationTemp = parseFloat(unitCalibrationTempF);
    const actualTemp = parseFloat(actualTempF);
    if (isNaN(specificGravity) || isNaN(calibrationTemp) || isNaN(actualTemp)) return;

    const corrected = specificGravity * (1 + 0.00025 * ((actualTemp - 32) * 5 / 9 - (calibrationTemp - 32) * 5 / 9));
    setCorrectedReading(corrected.toFixed(4));
  };

  return (
    <div className="equipment-calculator">
      <h2>Hydrometer Calibration</h2>
      <div>
        <input
          className='input'
          type="number"
          placeholder="Hydrometer Reading"
          value={specificGravityRead}
          onChange={e => setSpecificGravityRead(e.target.value)}
        />
        <input
          className='input'
          type="number"
          placeholder="Unit Calibration Temp (°F)"
          value={unitCalibrationTempF}
          onChange={e => setUnitCalibrationTempF(e.target.value)}
        />
        <input
          className='input'
          type="number"
          placeholder="Water Temp (°F)"
          value={actualTempF}
          onChange={e => setActualTempF(e.target.value)}
        />
      </div>
      <button className='button' onClick={calculateCorrection}>Calculate</button>
      {correctedReading !== null && (
        <div>
          <p className='calculation'>Corrected Reading: {correctedReading}</p>
        </div>
      )}
    </div>
  );
};

export default HydrometerCalibration;

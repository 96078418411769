import React from 'react';
import Salt from './Salinity/Salt';
import Final from './Salinity/Final';
import Target from './Salinity/Target';
import RODI from './Salinity/RODI';

const Salinity = ({ salinityType }) => {
  const renderCalculator = () => {
    switch (salinityType) {
      case 'final':
        return <Final />;
      case 'target':
        return <Target />;
      case 'RODI':
        return <RODI />;
      case 'salt':
      default:
        return <Salt />;
    }
  };

  return (
    <div className="salinity-calculator">
      <h2>Salinity Calculator</h2>
      {renderCalculator()}
    </div>
  );
};

export default Salinity;

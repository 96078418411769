import React, { useState } from 'react';
import { format, addDays } from 'date-fns';
import '../styles.css';

const TTM = () => {
  const [selectedMethod, setSelectedMethod] = useState('');
  const [startDate, setStartDate] = useState('');
  const [timeline, setTimeline] = useState([]);
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState('');

  const methods = {
    'Marine Ich TTM': [
      { day: 1, task: 'Fish is placed in initial QT (transfer tank).' },
      { day: 4, task: 'Transfer the Fish to a New Tank' },
      { day: 7, task: 'Transfer the Fish to a New Tank' },
      { day: 10, task: 'Transfer the Fish to a New Tank' },
      { day: 13, task: 'Transfer the fish to a final tank, and you\'re done' }
    ],
    'Enhanced TTM (Velvet and Brooke)': [
      { day: 1, task: 'Fish is placed in initial QT (transfer tank).' },
      { day: 4, task: 'Perform 30 minute Hydrogen Peroxide bath OR 45 minute Formalin bath, then transfer the fish to a new tank.' },
      { day: 7, task: 'Transfer the fish to a new tank.' },
      { day: 10, task: 'Perform 30 minute Hydrogen Peroxide bath OR 45 minute Formalin bath, then transfer the fish to a new tank.' },
      { day: 13, task: 'Transfer the fish to a final tank, and you\'re done' }
    ],
    'Marine Ich and Velvet TTM': [
      { day: 1, task: 'Fish in tank' },
      { day: 2, task: '1st transfer at 36 hours' },
      { day: 4, task: '2nd transfer at 36 hours' },
      { day: 5, task: '3rd transfer at 36 hours' },
      { day: 7, task: '4th transfer at 36 hours' },
      { day: 10, task: '5th transfer at 71 hours' },
      { day: 13, task: '6th transfer at 71 hours' }
    ]
  };

  const generateTimeline = () => {
    if (!selectedMethod || !startDate) {
      setError('Please select a method and start date.');
      return;
    }

    setError('');

    const methodSteps = methods[selectedMethod];
    const initialDate = new Date(startDate);
    const generatedTimeline = methodSteps.map((step) => {
      const date = format(addDays(initialDate, step.day), 'EEE MM/dd');
      return {
        day: step.day,
        date,
        task: step.task
      };
    });

    setTimeline(generatedTimeline);
  };

  const printTimeline = () => {
    window.print();
  };

  const copyToClipboard = () => {
    const timelineText = timeline.map(item => `Day ${item.day} [${item.date}]: ${item.task}`).join('\n');
    navigator.clipboard.writeText(timelineText).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }).catch((error) => console.log('Error copying to clipboard:', error));
  };

  return (
    <div className="ttm-calculator">
      <div className="dropdown-container">
        <label>Choose TTM Method: </label>
        <select
          className='dropdown'
          value={selectedMethod}
          onChange={e => setSelectedMethod(e.target.value)}
        >
          <option value="">Select a Method</option>
          {Object.keys(methods).map((method, index) => (
            <option key={index} value={method}>{method}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Select Start Date: </label>
        <input
          className='input'
          type="date"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
        />
      </div>
      <button className='button' onClick={generateTimeline}>Calculate</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {timeline.length > 0 && (
        <div>
          <button className='icon-button' onClick={printTimeline} title="Print Timeline">
            🖨️
          </button>
          <button className='icon-button' onClick={copyToClipboard} title="Copy to Clipboard">
            📋
          </button>
          <h3>Tank Transfer Method Timeline</h3>
          {timeline.map((item, index) => (
            <div key={index} style={{ marginBottom: '10px', textAlign: 'left' }}>
              <p><span style={{ fontWeight: 'bold' }}>Day {item.day}:</span> <span style={{ color: 'blue' }}>[{item.date}]</span> {item.task}</p>
            </div>
          ))}
          <p style={{ fontSize: 'smaller', marginTop: '20px' }}>
            This schedule is based on information from the Humblefish website. Credit for the original data goes to <a href="https://humble.fish/community/index.php?threads/tank-transfer-method-all-versions.6800/" target="_blank" rel="noopener noreferrer">Humblefish</a>.
          </p>
        </div>
      )}
    </div>
  );
};

export default TTM;

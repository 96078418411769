import React, { useState, useEffect } from 'react';

const EditEvent = () => {
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [formData, setFormData] = useState({
    episode: '',
    title: '',
    date: '',
    desc: '',
    link: '',
    image: '',
    time: '',
  });
  const [showDropdown, setShowDropdown] = useState(true);

  const fetchEventData = () => {
    fetch('https://api-lqdwzbqqpa-uc.a.run.app/events')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setEvents(data)
      })
      .catch(error => console.error('Error fetching events:', error));
  }

  useEffect(() => {
    fetchEventData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (window.confirm('Do you want to proceed with the changes?')) {
      const apiEndpoint = `https://api-lqdwzbqqpa-uc.a.run.app/event/${selectedEvent}`;
      const token = localStorage.getItem('AuthToken');

      fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Event updated successfully:', data);
        alert('Event successfully updated.');
        fetchEventData();
        setSearch('');
        setShowDropdown(true);
        setFormData({
          episode: '',
          title: '',
          date: '',
          desc: '',
          link: '',
          image: '',
          time: '',
        });
      })
      .catch(error => console.error('Error updating event:', error));
    }
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      const apiEndpoint = `https://api-lqdwzbqqpa-uc.a.run.app/event/${selectedEvent}`;
      const token = localStorage.getItem('AuthToken');

      fetch(apiEndpoint, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => response.json())
      .then(data => {
        console.log('Event deleted successfully:', data);
        alert('Event successfully deleted.');
        fetchEventData();
        setSearch('');
        setShowDropdown(true);
        setFormData({
          episode: '',
          title: '',
          date: '',
          desc: '',
          link: '',
          image: '',
          time: '',
        });
      })
      .catch(error => console.error('Error deleting event:', error));
    }
  };

  const filteredEvents = events.filter((event) => event.title && event.title.toLowerCase().includes(search.toLowerCase()));

  return (
    <div>
      <input
        style={{width: '90%', backgroundColor: 'lightgray'}}
        type="text"
        placeholder="Search episode"
        value={search}
        onChange={(e) => { setSearch(e.target.value); setShowDropdown(true); }}
      />
      {showDropdown && (
        <ul style={{
            textAlign: 'left', 
            listStyleType: 'none', 
            backgroundColor: 'lightgray',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.2)',
            padding: '5px',
            height: '80px',
            width: '88%',
            margin: '0 auto',
            cursor: 'pointer'
        }}>
          {filteredEvents.map((event, index) => (
            <li key={index} onClick={() => {
              const selectedData = events.find(item => item.title === event.title);
              setSearch(selectedData.title);
              setSelectedEvent(selectedData.episode);
              setFormData({
                episode: selectedData.episode,
                title: selectedData.title,
                date: selectedData.date,
                desc: selectedData.desc,
                link: selectedData.link,
                image: selectedData.image,
                time: selectedData.time,
              });
              setShowDropdown(false);
            }}>
              {event.title}
            </li>
          ))}
        </ul>
      )}
      {selectedEvent && (
        <form onSubmit={handleSubmit} style={{display: 'grid', gridTemplateColumns: '1fr', textAlign: 'center', marginTop: '20px' }}>
            <label style={{marginBottom: '10px'}}>
            Episode:
            <br />
            <input style={{marginTop: '5px'}} type="text" name="episode" value={formData.episode} onChange={handleChange} required />
            </label>
            <label style={{marginBottom: '10px'}}>
            Title:
            <br />
            <input style={{marginTop: '5px'}} type="text" name="title" value={formData.title} onChange={handleChange} required />
            </label>
            <label style={{marginBottom: '10px'}}>
            Date:
            <br />
            <input style={{marginTop: '5px'}} type="date" name="date" value={formData.date} onChange={handleChange} />
            </label>
            <label style={{marginBottom: '10px'}}>
            Time:
            <br />
            <input style={{marginTop: '5px'}} type="time" name="time" value={formData.time} onChange={handleChange} />
            </label>
            <label style={{marginBottom: '10px'}}>
            Description:
            <br />
            <textarea style={{marginTop: '5px'}} name="desc" value={formData.desc} onChange={handleChange} required ></textarea>
            </label>
            <label style={{marginBottom: '10px'}}>
            Image:
            <br />
            <input style={{marginTop: '5px'}} type="text" name="image" value={formData.image} onChange={handleChange} />
            </label>
            <label style={{marginBottom: '10px'}}>
            Link:
            <br />
            <input style={{marginTop: '5px'}} type="text" name="link" value={formData.link} onChange={handleChange} />
            </label>
          <button type="submit" style={{ borderRadius: '10px', width: '200px', margin: '0 auto', marginTop: '10px', padding: '7px', cursor: 'pointer'}}>Submit</button>
          <button type="button" onClick={handleDelete} style={{ borderRadius: '10px', width: '200px', margin: '0 auto', marginTop: '10px', padding: '7px', cursor: 'pointer', backgroundColor: 'red', color: 'white'}}>Delete</button>
        </form>
      )}
    </div>
  );
};

export default EditEvent;

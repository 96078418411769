import React, { useState, useEffect } from "react";
import './Glossary.css';
import loadingImage from '../images/Fish.gif'; 

const Glossary = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [dictionary, setDictionary] = useState([]);

  useEffect(() => {
    fetch('https://api-lqdwzbqqpa-uc.a.run.app/glossary')
      .then(response => response.json())
      .then(data => {
        const sortedData = data.sort((a, b) => a.term.localeCompare(b.term));
        setDictionary(sortedData);
      })
      .catch(error => console.error('Error fetching glossary terms:', error));
  }, []);

  const filteredDictionary = dictionary.filter(
    item => item.term.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.tags.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!dictionary.length) {
    return (
      <div className="loading-container">
        <img src={loadingImage} alt="Loading..." className="loading-image" />
      </div>
    );
  }

  const clearSearch = () => {
    setSearchTerm("");
  };

  return (
    <div className="glossary-container">
      <div className="header">
        <h1 className="header-title">Reefer's Dictionary</h1>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search by Term or Tags"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          {searchTerm && <button className="clear-button" onClick={clearSearch}>X</button>}
        </div>
        <select onChange={e => setSearchTerm(e.target.value)}>
          <option value="">Select a Topic</option>
          <option value="Fish disease">Fish Disease</option>
          <option value="Plumbing">Plumbing</option>
          <option value="Equipment">Equipment</option>
          <option value="Cycling">Cycling</option>
          <option value="Tank type">Tank Type</option>
          <option value="Medication">Medication</option>
          <option value="Quarantine">Quarantine</option>
          <option value="Chemistry">Chemistry</option>
        </select>
      </div>
      <div className="items">
        <ul>
          {filteredDictionary.map((entry, index) => (
            <li key={index}>
              <span><strong>{entry.term}:</strong><p>{entry.desc}</p></span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Glossary;

import React, { useState } from 'react';

const AddEvent = () => {
  const [formData, setFormData] = useState({
    episode: '',
    title: '',
    date: '',
    desc: '',
    link: '',
    image: '',
    time: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const apiEndpoint = 'https://api-lqdwzbqqpa-uc.a.run.app/event';
    const token = localStorage.getItem('AuthToken');

    fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Success:', data);
      alert('Term successfully added.');
      setFormData({
        episode: '',
        title: '',
        date: '',
        desc: '',
        link: '',
        image: '',
        time: '',
      })
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Error adding event.');
    });
  };

  return (
    <form onSubmit={handleSubmit} style={{display: 'grid', gridTemplateColumns: '1fr', textAlign: 'center' }}>
      <label style={{marginBottom: '10px'}}>
        Episode:
        <br />
        <input style={{marginTop: '5px'}} name="episode" value={formData.episode} onChange={handleChange} required />
      </label>
      <label style={{marginBottom: '10px'}}>
        Title:
        <br />
        <input style={{marginTop: '5px'}} type="text" name="title" value={formData.title} onChange={handleChange} required />
      </label>
      <label style={{marginBottom: '10px'}}>
        Date:
        <br />
        <input style={{marginTop: '5px'}} type="date" name="date" value={formData.date} onChange={handleChange} />
      </label>
      <label style={{marginBottom: '10px'}}>
        Time:
        <br />
        <input style={{marginTop: '5px'}} type="time" name="time" value={formData.time} onChange={handleChange} />
      </label>
      <label style={{marginBottom: '10px'}}>
        Description:
        <br />
        <textarea style={{marginTop: '5px'}} name="desc" value={formData.desc} onChange={handleChange} required ></textarea>
      </label>
      <label style={{marginBottom: '10px'}}>
        Image:
        <br />
        <input style={{marginTop: '5px'}} name="image" value={formData.image} onChange={handleChange} />
      </label>
      <label style={{marginBottom: '10px'}}>
        Link:
        <br />
        <input style={{marginTop: '5px'}} name="link" value={formData.link} onChange={handleChange} />
      </label>
      <button style={{ borderRadius: '10px', width: '200px', margin: '0 auto', marginTop: '10px', padding: '7px', cursor: 'pointer'}} type="submit">Submit</button>
    </form>
  );
};

export default AddEvent;
import React, { useState, useEffect } from 'react';
import calculationsData from './data/sump.json'; 
import './styles.css';

const Sump = () => {
  const [chambers, setChambers] = useState(2);
  const [selectedShape, setSelectedShape] = useState('2-chamber');
  const [inputs, setInputs] = useState({});
  const [result, setResult] = useState(null);
  const [inputUnit, setInputUnit] = useState('inches'); 
  const [outputUnit, setOutputUnit] = useState('gallons');

  useEffect(() => {
    setSelectedShape(`${chambers}-chamber`);
    setInputs({});
    setResult(null);
  }, [chambers]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: parseFloat(value)
    }));
  };

  const handleUnitChange = (event) => {
    setInputUnit(event.target.value);
    setResult(null);
  };

  const handleOutputUnitChange = (event) => {
    setOutputUnit(event.target.value);
    setResult(null);
  };

  const calculateVolume = () => {
    const calculation = calculationsData.find((calc) => calc.shape === selectedShape);
    const formula = new Function(...calculation.inputs, `return ${calculation.formula};`);
    let calculatedVolume = formula(...calculation.inputs.map(input => inputs[input]));

    if (inputUnit === 'inches') {
      calculatedVolume /= 231; // Convert cubic inches to gallons
    } else if (inputUnit === 'feet') {
      calculatedVolume *= 7.48052; // Convert cubic feet to gallons
    } else {
      calculatedVolume *= 264.172; // Convert cubic meters to gallons
    }

    if (outputUnit === 'liters') {
      calculatedVolume *= 3.78541; // Convert gallons to liters
    }

    setResult(calculatedVolume);
  };

  const getImagePath = (shape) => {
    try {
      return require(`./images/sump/${shape}.png`);
    } catch {
      return null;
    }
  };

  return (
    <div>
      <div className="dropdown-container">
        <label>Number of Chambers: </label>
        <select
          className='dropdown'
          value={chambers}
          onChange={(e) => setChambers(e.target.value)}
        >
          <option value={2}>2-Chambers</option>
          <option value={3}>3-Chambers</option>
          <option value={4}>4-Chambers</option>
        </select>
      </div>
      <div className="sump-image">
        {getImagePath(selectedShape) && (
          <img src={getImagePath(selectedShape)} alt={selectedShape} />
        )}
      </div>
      <div>
        {calculationsData.find((calc) => calc.shape === selectedShape).inputs.map((input) => (
          <input
            className='input'
            key={input}
            type="number"
            name={input}
            value={inputs[input] || ''}
            onChange={handleInputChange}
            placeholder={input.charAt(0).toUpperCase() + input.slice(1)}
            required
          />
        ))}
      </div>
      <div className='unit-selector'>
        <label>
          <input type="radio" value="inches" name="inputUnit" 
                 checked={inputUnit === 'inches'} onChange={handleUnitChange} />
          Inches
        </label>
        <label>
          <input type="radio" value="feet" name="inputUnit" 
                 checked={inputUnit === 'feet'} onChange={handleUnitChange} />
          Feet
        </label>
        <label>
          <input type="radio" value="meters" name="inputUnit" 
                 checked={inputUnit === 'meters'} onChange={handleUnitChange} />
          Meters
        </label>
      </div>
      <button className='button' onClick={calculateVolume}>Calculate</button>
      <div className='unit-selector'>
        <label>
          <input type="radio" value="gallons" name="outputUnit" 
                 checked={outputUnit === 'gallons'} onChange={handleOutputUnitChange} />
          Gallons
        </label>
        <label>
          <input type="radio" value="liters" name="outputUnit" 
                 checked={outputUnit === 'liters'} onChange={handleOutputUnitChange} />
          Liters
        </label>
      </div>
      {result !== null && (
        <div className='result'>
          <h4 className='calculation'>Volume: {result.toFixed(2)} {outputUnit}</h4>
        </div>
      )}
    </div>
  );
};

export default Sump;

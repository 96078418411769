import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="white">
      {'Copyright © '}
      <Link color="inherit" href="https://sliceofthereef.org/">
        Slice of the Reef
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function StickyFooter() {
  return (
      <Box
        sx={{
          textAlign: 'center',
          marginTop: 'auto',
          backgroundColor: '#606D89',
          color: 'white', 
          padding: '1em', 
          opacity: '90%'
        }}
      >
        <Copyright />
      </Box>
  );
}
// Import modules
import React, { useState } from "react";

const Resources = () => {
  // Sample data for resources
  const resourceData = [
    {
      id: 1,
      title: "React Documentation",
      description: "Official React documentation to get you started.",
      tags: ["React", "Documentation"],
      imgSrc: "https://via.placeholder.com/150",
    },
    {
      id: 2,
      title: "CSS Tricks",
      description: "A website that helps you master CSS.",
      tags: ["CSS", "Design"],
      imgSrc: "https://via.placeholder.com/150",
    },
    {
      id: 3,
      title: "JavaScript Info",
      description: "All you need to know about JavaScript.",
      tags: ["JavaScript", "Tutorial"],
      imgSrc: "https://via.placeholder.com/150",
    },
  ];

  // State to hold search query
  const [searchQuery, setSearchQuery] = useState("");

  // Render individual resource item
  const renderResourceItem = (resource) => (
    <div key={resource.id} className="resource-item" style={{ borderRadius: '12px', margin: '20px', padding: '20px', backgroundColor: 'white', boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.2)'  }}>
      <img src={resource.imgSrc} alt={resource.title} />
      <h2>{resource.title}</h2>
      <p>{resource.description}</p>
      <div className="tags" style={{ fontSize: '12px', color: 'gray' }}>
        Tags: 
        {resource.tags.map((tag, index) => (
          <span key={index}> {tag} </span>
        ))}
      </div>
    </div>
  );

  // Filter resources based on search query
  const filteredResources = resourceData.filter((resource) =>
    resource.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '90%', margin: '0 auto', fontFamily: 'Roboto', opacity: '90%' }}>
      <input
        type="text"
        placeholder="Search Resources"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ width: '90%', borderRadius: '5px', margin: '20px auto', display: 'block', height: '20px', boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.2)', border: 'none'  }}
      />
      <div className="resource-list">
        {filteredResources.map(renderResourceItem)}
      </div>
    </div>
  );  
};

// Export the component for use in other parts of the application
export default Resources;

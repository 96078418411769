import React, { useState } from 'react';
import '../styles.css';

const RODI = () => {
  const [initialSalinity, setInitialSalinity] = useState('');
  const [finalSalinity, setFinalSalinity] = useState('');
  const [volumeUnit, setVolumeUnit] = useState('gallons');
  const [volume, setVolume] = useState('');
  const [RODIVolume, setRODIVolume] = useState(null);
  const [error, setError] = useState(null);

  const calculateRODIVolume = () => {
    if (!initialSalinity || !finalSalinity || !volume) {
      setError('Please fill out all required fields');
      return;
    }

    const S0 = parseFloat(initialSalinity);
    const Sf = parseFloat(finalSalinity);
    const V = parseFloat(volume);

    const Va = (S0 * V / Sf) - V;
    setRODIVolume(Va);
    setError(null);
  };

  return (
    <div>
        <p>Find out how much RODI water is needed to lower the salinity to a desired level.</p>
      <div>
        <input className='input' type="number" placeholder='Initial Salinity' value={initialSalinity} onChange={e => setInitialSalinity(e.target.value)} />
      </div>
      <div>
        <input className='input' type="number" placeholder='Final Salinity' value={finalSalinity} onChange={e => setFinalSalinity(e.target.value)} />
      </div>
      <div>
        <input className='input' type="number" placeholder='Total Volume' value={volume} onChange={e => setVolume(e.target.value)} />
      </div>
      <div>
        <label>
          <input type="radio" value="gallons" name="volumeUnit" checked={volumeUnit === 'gallons'} onChange={() => setVolumeUnit('gallons')} />
          Gallons
        </label>
        <label>
          <input type="radio" value="liters" name="volumeUnit" checked={volumeUnit === 'liters'} onChange={() => setVolumeUnit('liters')} />
          Liters
        </label>
      </div>
      <button className='button' onClick={calculateRODIVolume}>Calculate</button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {RODIVolume !== null && <div className='calculation'>RODI Volume Needed: {RODIVolume.toFixed(2)} {volumeUnit}</div>}
    </div>
  );
};

export default RODI;
import React from 'react';
import TTM from './QT/TTM';
import Copper from './QT/Copper';
import Medication from './QT/Medication';

const Quarantine = ({ quarantineType }) => {
  const renderCalculator = () => {
    switch (quarantineType) {
      case 'tankTransfer':
        return <TTM />;
      case 'copper':
        return <Copper />;
      case 'medication':
        default:
        return <Medication />;
    }
  };

  return (
    <div className="quarantine-calculator">
      <h2>Quarantine Calculator</h2>
      {renderCalculator()}
    </div>
  );
};

export default Quarantine;
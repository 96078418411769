import React, { useState } from 'react';
import medicationsData from '../data/medications.json';
import medicationCodesData from '../data/medicationCodes.json';
import '../styles.css';

const Medication = () => {
  const [volume, setVolume] = useState('');
  const [volumeUnit, setVolumeUnit] = useState('gallons');
  const [selectedMedication, setSelectedMedication] = useState('');
  const [recommendation, setRecommendation] = useState('');
  const [codeMessages, setCodeMessages] = useState([]);

  const litersToGallons = (liters) => liters * 0.264172;

  const calculateMedication = () => {
    const medicationDetails = medicationsData.find(med => med.Name === selectedMedication);
    if (!medicationDetails) return;

    const volumeInGallons = volumeUnit === 'liters' ? litersToGallons(parseFloat(volume)) : parseFloat(volume);
    const recommendationAmount = volumeInGallons * medicationDetails['Amount Per Gallon'];

    const messages = medicationDetails.Code.map((code, index) => {
      const codeDetail = medicationCodesData.find(c => c.Code === code);
      const stars = '*'.repeat(index + 1); // Generate stars based on the index
      return codeDetail ? `${stars} ${codeDetail.Message}` : `${stars} No message found for code`;
    });

    setRecommendation(`${recommendationAmount.toFixed(2)} ${medicationDetails.Unit}`);
    setCodeMessages(messages);
  };

  return (
    <div className="medication-calculator">
      <div>
        <input
          className='input' 
          type="number"
          placeholder="Volume"
          value={volume}
          onChange={e => setVolume(e.target.value)}
        />
        <label>
          <input
            type="radio"
            value="gallons"
            checked={volumeUnit === 'gallons'}
            onChange={() => setVolumeUnit('gallons')}
          />
          Gallons
        </label>
        <label>
          <input
            type="radio"
            value="liters"
            checked={volumeUnit === 'liters'}
            onChange={() => setVolumeUnit('liters')}
          />
          Liters
        </label>
      </div>
      <div>
        <label>Choose Medication: </label>
        <select
          className='dropdown'
          value={selectedMedication}
          onChange={(e) => {
            setSelectedMedication(e.target.value);
            setRecommendation('');
            setCodeMessages([]);
          }}
        >
          <option value="">Select a Medication</option>
          {medicationsData.map((med, index) => (
            <option key={index} value={med.Name}>{med.Name}</option>
          ))}
        </select>
      </div>
      <button className='button' onClick={calculateMedication}>Calculate</button>
      {recommendation && (
        <div>
          <p className='calculation'>Recommended Amount: {recommendation}</p>
          {codeMessages.map((message, index) => (
            <p key={index} style={{fontSize: 'smaller', marginTop: '0.5em'}}>
              {message}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Medication;
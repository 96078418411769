import React, { useState } from 'react';
import '../styles.css';

const Final = () => {
  const [initialSalinity, setInitialSalinity] = useState('');
  const [replacementSalinity, setReplacementSalinity] = useState('');
  const [volume, setVolume] = useState('');
  const [volumeReplaced, setVolumeReplaced] = useState('');
  const [finalSalinity, setFinalSalinity] = useState(null);
  const [error, setError] = useState(null);

  const calculateFinalSalinity = () => {
    if (!initialSalinity || !replacementSalinity || !volume || !volumeReplaced) {
      setError('Please fill out all required fields');
      return;
    }

    const S0 = parseFloat(initialSalinity);
    const Sn = parseFloat(replacementSalinity);
    const V = parseFloat(volume);
    const r = parseFloat(volumeReplaced);

    const Sf = (S0 * (V - r) + Sn * r) / V;
    setFinalSalinity(Sf);
    setError(null);
  };

  return (
    <div>
        <p>Calculate the tank's salinity after replacing a certain volume of water with another of a different salinity.</p>
      <div>
        <input className='input' type="number" placeholder='Initial Salinity' value={initialSalinity} onChange={e => setInitialSalinity(e.target.value)} />
      </div>
      <div>
        <input className='input' type="number" placeholder='Replacement Salinity' value={replacementSalinity} onChange={e => setReplacementSalinity(e.target.value)} />
      </div>
      <div>
        <input className='input' type="number" placeholder='Total Volume' value={volume} onChange={e => setVolume(e.target.value)} />
      </div>
      <div>
        <input className='input' type="number" placeholder='Volume Replaced' value={volumeReplaced} onChange={e => setVolumeReplaced(e.target.value)} />
      </div>
      <button className='button' onClick={calculateFinalSalinity}>Calculate</button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {finalSalinity !== null && <div className='calculation'>Final Salinity: {finalSalinity.toFixed(2)}</div>}
    </div>
  );
};

export default Final;